<template>
  <div>
    <div>
      <b-card>
        <modal-actions class="mb-3" @close="$router.push({ name: 'organisations' })" />
        <div class="d-flex flex-wrap justify-content-between align-items-center">
          <p class="h2 text-secondary">Modifier organisation</p>
          <b-button variant="secondary">Confirmer</b-button>
          <hr class="w-100" />
        </div>

        <div class="row my-4">
          <div class="col-12 col-md-6">
            <b-form-group label-cols="4" label-cols-lg="3" label="Entité juridique">
              <b-form-input type="text"></b-form-input>
            </b-form-group>
            <b-form-group label-cols="4" label-cols-lg="3" label="Libellé">
              <b-form-select
                :options="['Libellé 1', 'Libellé 2', 'Libellé 3']"
              ></b-form-select>
            </b-form-group>
            <b-form-group label-cols="4" label-cols-lg="3" label="Code organisation">
              <b-form-input type="text"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-12 col-md-6">
            <b-form-group
              label-cols="4"
              label-cols-lg="3"
              label="Organisation de rattachement"
            >
              <b-form-select :options="['DDA', 'DFA']"></b-form-select>
            </b-form-group>
            <b-form-group label-cols="4" label-cols-lg="3" label="Niveau">
              <b-form-input type="number"></b-form-input>
            </b-form-group>
            <b-form-group label-cols="4" label-cols-lg="3" label="Type">
              <b-form-select :options="['Direction', 'Département']"></b-form-select>
            </b-form-group>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import countries from "@/assets/countries/data.json";
import ModalActions from "@/components/custom/ModalActions.vue";

export default {
  components: { ModalActions },
  data: () => ({
    countries: countries,
    selectedCountries: [],
  }),
  methods: {},
};
</script>

<style></style>
